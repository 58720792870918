@charset "UTF-8";
@import (reference) "_includes/_vars.less";
@import (reference) "_includes/_mixins.less";
@import "ground.less";
@import "aos.css";
@import "fullpage.min.css";
@import "hamburgers.min.css";


/*---------------------------------------*\
   Header
\*---------------------------------------*/

.header {
  width: 100%;
  color: @text;
  background: @white;
  .transition-default();
  &__logo {
    line-height: 1;
    padding: @offset 0;
    float: left;
    text-align: center;
    transform: scale(1);
    position: relative;
    left: 0;
    top: 1rem;
    @media(max-width: @screen_desktop_max) {
      left: @offset;
    }

    a {
      &:extend(.clear);
    }
    img {
      &:extend(.img_responsive all);
      max-width: 125 / @bs * 1rem;
      @media(max-width: 1024px) {
        max-width: 89px;
        padding-top: 8px;
      }
    }
  }
  &__menu {
    display: none;
    margin-bottom: @offset;
    .fs_px(14);
    font-weight: 400;
    color: @text;
    text-align: center;
    &:extend(.clear);
    ul {
      li {
        display: block !important;
        width: 100%;
        opacity: 0;
        a {
          width: 100%;
          padding: @offset @offset @offset 0;
          opacity: 1;
          color: @text;
          .hover({
            color: @primary;
          });
        }
      }
    }
    body.screen_md &, body.screen_lg & {
      width: auto;
      display: block !important;
      position: relative;
      right: 0;
      top: @offset;
      margin-left: @offset-md;
      @media(max-width: 1120px) {
        margin-left: @offset;
      }

      ul {
        li {
          opacity: 1 !important;
          display: inline-block !important;
          width: auto;
          a {
            padding: @offset-md;
            @media(max-width: 1417px) {
              padding: @offset-md @offset;
            }
            span {
              .active {
                &:after {
                  border-bottom: 2px solid @primary;
                }
              }
            }
          }
        }
      }
    }
  }

  &__toggle {
    cursor: pointer;
    margin-right: 5px;
    position: absolute;
    top: @offset-md;
    right: @offset;
    &:extend(.not_selectable all);
    .transition-default();

    body.screen_md &, body.screen_lg & {
      display: none !important;
    }
  }
  .menu_active & {
    .bga(@black, 0.9);
  }
  .menu_active.screen_sm & {
    background: transparent !important;
  }
}

.line {
  position: relative;
  height: 100%;
  width: 2px;
  background-color: #ccc;
  margin: auto;
  body.screen_sm &, body.screen_xs &, body.screen_xxs & {
    display: none !important;
  }
  &__bullets {
    position: absolute;
    bottom: 20%;
    left: -13px;
    a {
      display: block;
      width: 26px;
      height: 26px;
      border: 2px solid #b7b7b7;
      border-radius: 50%;
      background-color: #ffffff;
      margin-bottom: @offset;
      &:extend(.transition all);
      &:hover {
        -ms-transform: scale(1.2); /* IE 9 */
        -webkit-transform: scale(1.2); /* Safari */
        transform: scale(1.2); /* Standard syntax */
      }
    }

    .activeb {
      background-image: linear-gradient(to top, #306d91 0%, #50ade1 100%);
    }
    .activer {
      background-color: @primary;
    }
  }
}

/*---------------------------------------*\
   sections
\*---------------------------------------*/
section {
  p {
    .fs(20);
  }
}

.about, .services, .career, .contact {
  @media(min-width: @screen_desktop) {
    height: 100%;
  }
  .col-main {
    width: 65%;
    height: 100%;
    @media(max-width: @screen_desktop_max) {
      width: 100%;
    }
  }
  .col-aside {
    width: 35%;
    height: 100%;
    @media(max-width: @screen_desktop_max) {
      width: 100%;
      height: unset;
    }
  }
  .blue-to-right {
    background-image: linear-gradient(to right, #306d91 0%, #50ade1 100%);
  }
  .red {
    background-color: @primary;
  }

  &__content {
    max-width: 525px;

    h1 {
      padding-bottom: @offset;
      @media(min-height: 700px) {
        padding-bottom: @offset-md;
      }
    }
    p {
      .fs(15);
      @media(min-height: 700px) {
        .fs(17)
      }

    }
    address {
      .fs(15);
      font-style: normal;
    }
    ul {
      li {
        color: #499fd0;
        span {
          color: @text
        }
      }
    }
  }
}

.about {
  .col-aside {
    @media(max-width: @screen_desktop_max) {
      height: 500px;
    }
  }
  aside {
    height: 80%;
    background-repeat: no-repeat;
    background-position: center top;
    margin: 0 auto;
  }
}


.services {
  aside {
    padding: @offset-md;
    color: @white;
    @media(max-width: @screen_desktop_max) {
      text-align: center;
    }
    h2 {
      .fs(16);
      margin:0;
      margin-bottom: @offset;
      font-weight: 400;
      font-style: italic;
      @media(min-width: 1300px) {
        .fs(18);
      }
    }
    p {
      .fs(14);
      @media(min-width: 1300px) {
        .fs(16);
      }
      font-weight: 300;

    }
    img {
      &:extend(.img_responsive all);
      display: block;
      @media(max-width: @screen_desktop_max) {
        padding-bottom: @offset;
      }
      &.first {
        max-width: 115px;
        margin: auto;
      }
      &.scnd {
        max-width: 70px;
        margin: auto;

      }
      &.trd {
        max-width: 80px;
        margin: auto;

      }
    }
    .row {
      margin-bottom: @offset-md;
    }
  }
  &__title {
    text-align: center;
    margin-bottom: @offset-md;
    h1 {
      display: inline-block;
      color: #5eb4e7;
      background-color: #ffffff;
      padding: ( 15 / @bs * 1rem) (45 / @bs * 1rem);
      text-transform: uppercase;
      .fs(22);
    }
  }
}

.career {

  &__text {
    .fs(22);
    color: @white;
    font-style: italic;
    font-weight: 300;
    text-align: center;
    margin-top: @offset-md;
    padding: @offset-lg @offset-md;
    @media(min-width: @screen_desktop) {
      padding: @offset-lg;
    }
    @media(min-width: 1300px) {
      margin-top: @offset-lg;
      .fs(26);
    }
    @media(min-height: 900px) {
      padding-top: @offset-xxxl ;
    }
  }
  &__image {
    max-width: 523px;
    margin: auto;
    img {
      &:extend(.img_responsive all);
      padding: @offset-md @offset-lg;
    }
  }
}

.contact {
  aside {
    padding: @offset-md 0;
    color: @white;
    @media(max-width: @screen_desktop_max) {
      text-align: center;
    }
  }
  &__title {
    text-align: center;
    margin-bottom: @offset-md;
    h1 {
      display: inline-block;
      color: #5eb4e7;
      background-color: #ffffff;
      padding: ( 15 / @bs * 1rem) (45 / @bs * 1rem);
      text-transform: uppercase;
      .fs(22);
    }
  }
  &__wrap {
    padding: @offset-lg;
    @media(max-width: 1024px){
      padding: @offset;
    }
  }
}

/*---------------------------------------*\
   Footer
\*---------------------------------------*/
.footer {
  clear: both;
}

/*---------------------------------------*\
   Font sizes Media Query
\*---------------------------------------*/
@font_size_mobile: 13;
@font_size_tablet: 14;
@font_size_desktop: 15;
@font_size_large: 16;

/*---------------------------------------*\
   App Media Query
\*---------------------------------------*/
 @media (min-width: @screen_mobile) {
  html {
    font-size:unit(@font_size_mobile,px);
  }

}
@media (min-width: @screen_tablet) {
  html {
    font-size:unit(@font_size_tablet,px);
  }
}
@media (min-width: @screen_desktop) {
  html {
    font-size:unit(@font_size_desktop,px);
  }
}
@media (min-width: @screen_large) {
  html {
    font-size:unit(@font_size_large,px);
  }
}

.col.md-12.padding-bottom-50 {
  padding-bottom: 50px;
}

p {
  font-size: 1rem !important;
}