@import (reference) "_vars.less";
/*---------------------------------------*\
   Typography
\*---------------------------------------*/

.title1 {
  .ff();
  font-weight: inherit;
  font-size: inherit;
  color: inherit;
  strong, span {
    display: block;
  }
}

.title1 {
  .fs(28);
  font-weight: 700;
}

/*---------------------------------------*\
   Mixins
\*---------------------------------------*/
// Container
.container() {
  margin-right: auto;
  margin-left: auto;
  padding-left:  @offset;
  padding-right: @offset;
  max-width: 100%;
  position: relative;
  @media (min-width: @screen_tablet) {
    width: @container-sm;
  }
  @media (min-width: @screen_desktop) {
    width: @container-md;
  }
  @media (min-width: @screen_large) {
    width: @container-lg;
  }
}
// Base Grid mixins
.grid_row() {
  margin-left: -(@offset);
  margin-right: -(@offset);
  &:before,
  &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}
.grid_col() {
  width: 100%;
  float: left;
  position: relative;
  min-height: 1px;
  padding-left: @offset;
  padding-right: @offset;
}

.transition-default(@element: all) {
  transition: @element @time @ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.transition-none() {
  transition: none !important;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.bga(@color, @opacity) {
  background: rgba(red(@color), green(@color), blue(@color), @opacity);
}
.bg_set(@size: cover, @position: center center, @repeat: no-repeat) {
  background-size: @size;
  background-position: @position;
  background-repeat: @repeat;
}

.placeholder(@color) {
  &::-moz-placeholder {
    color: @color; opacity: 1;
  }
  &:-ms-input-placeholder {
    color: @color; opacity: 1;
  }
  &::-webkit-input-placeholder {
    color: @color; opacity: 1;
  }
}
.outline-reset() {
  :active, :focus, a:active, input:focus, button:focus, img, img:active, img:focus {
    outline: none !important;
  }
  ::-moz-focus-inner {
    border: none 0 !important;
  }
}
.aspect_ratio(@width, @height) {
  padding-top: (@height / @width) * 100%;
}
.img_responsive() {
  display: inline-block;
  max-width: 100%;
  width: 100%;
  height: auto;
}
.clear() {
  width: 100%;
  float: left;
}
.inline-icon {
  display: inline-block;
  width: 50px;
  height: 50px;
  position: relative;
  top: 10px;
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: @offset;
}
/*---------------------------------------*\
   Extends
\*---------------------------------------*/

.container {
  .container();
}
.absolute_align {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.ul_reset {
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    display: block;
    a {
      display: block;
    }
  }
}
.transition {
  .transitions({
    .transition-default();
  });
}
.clear {
  .clear();
}
.bg_set {
  .bg_set(cover, center center, no-repeat);
}
.img_responsive {
  .img_responsive();
}
.grid_row {
  .grid_row();
}
.grid_col {
  .grid_col();
}
