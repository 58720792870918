// Assets
@import (reference) "_includes/_vars.less";
// Extends
@import (reference) "_includes/_mixins.less";
// Normalize
@import "_includes/normalize.less";
// Bootstrap Grid
@import "_includes/grid.less";
// Custom Fonts
@import "_includes/_fonts.less";

/*---------------------------------------*\
   Base Styles
\*---------------------------------------*/
html, body {
  width: 100%;
  height: 100%;
}
html {
  font-size: @bs*1px;
}
body {
  overflow-x: hidden;
  .ff();
  font-size: 1rem;
  font-weight: 400;
  color: @text;
  overflow: auto;
  //opacity: 0;
  &:before {
    content: '';
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 99999999;
    background-color: @white;
    transition: all 0.8s linear;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  &.loaded {
    backface-visibility: hidden;
    &:before {
      opacity: 0;
      z-index: -1;
    }
  }
}

.wrap, .contentinfo {
  &:extend(.clear);
}

.wrapper {
  overflow: hidden;
  .container {
    border-left: 1px solid #e9e9eb;
  }
}

.underline {
  text-decoration: underline;
}

/*---------------------------------------*\
   Box Config
\*---------------------------------------*/
* {
  -webkit-tap-highlight-color: transparent;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}
*, *:before, *:after {
  box-sizing: border-box;
}
.container, header, section {
  position: relative;
}
div, article, section, main {
  &:before {
    content: '';
    display: table;
  }
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}
.container {
  &:extend(.container all);
}

/*---------------------------------------*\
   INIT Mixins
\*---------------------------------------*/
// Reset Outlines
.outline-reset();

/*---------------------------------------*\
   Default Styles
\*---------------------------------------*/
a {
  color: inherit;
  text-decoration: none;
  &:extend(.transition all);
  &:hover, &:active, &:focus {
    color: inherit;
    text-decoration: none;
  }
}
a:hover {
  color: @primary;
}
*[data-href], *[data-blank-href] {
  cursor: pointer;
}
h1, h2, h3, h4, h5 {
  small {
    display: block;
  }
}
p {
  display: inline-block;
  width: 100%;
  margin: 0 0 @offset 0;
}
nav {
  ul {
    &:extend(.ul_reset all);
  }
}
.regular {
  h1 {
    &:extend(.title1 all);
    padding: @offset-md 0;
    color: @secondary;
  }
  h2 {
    &:extend(.title2 all);
    color: @primary;
    font-style: italic;
    text-transform: uppercase;
    margin: @offset-sm 0;
  }
  h3 {
    &:extend(.title3 all);
    padding: @offset-md 0;
    color: @secondary;
  }
  p {
    .fs(20);
  }
  address {
    .fs(20);
    font-style:normal;
  }
  img {
    &:extend(.img_responsive all);
  }
}
.mb-4 {
  margin-bottom: 4rem!important;
}
.mb-2 {
  margin-bottom: 2rem;
}
.mtb-2 {
  margin-top: 2rem;
  margin-bottom: 2rem;

}
.p-2 {
  padding:@offset-md;
}
/*---------------------------------------*\
   Default Classes
\*---------------------------------------*/
.text-center {
  text-align: center !important;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.align-left {
  float: left !important;
}
.align-right {
  float: right !important;
}
.align-center {
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
.v-align {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}
.img_responsive {
  &:extend(.img_responsive all);
}
.clear {
  &:extend(.clear all);
}


/*---------------------------------------*\
   Forms
\*---------------------------------------*/


input[type="radio"],
input[type="checkbox"] {
  margin: 4px 0 0;
  margin-top: 1px \9; // IE8-9
  line-height: normal;
}
input[type="search"] {
  -webkit-appearance: none;
}
.form-group {
  margin-bottom: @offset;
}
.form-submit {
  margin-top: @offset-md;
}
.form-control {
  .fs(17);
  font-weight: 300;
  background: transparent;
  border: 1px solid @white;
  border-radius: 2px;
  color: @white;
  max-width: 100%;
  padding: (6 / @bs * 1rem) (16 / @bs * 1rem);
  line-height: (@bs + 10) / @bs * 1rem;
  width: 100%;
  height: 65 / @bs * 1rem;
  display: block;
  outline: 0 !important;
  box-shadow: none;
  -webkit-appearance: none;
  .placeholder(@white);
  &:-webkit-autofill {
    background: @white;
  }
  &[disabled],
  &[readonly],
  fieldset[disabled] & {
    cursor: not-allowed;
    opacity: 1;
    background: #fafafa;
  }
  textarea& {
    height: auto !important;
    min-height: 200px;
    .fs(17);
  }
  &:focus {
    border-color: @secondary;
  }
  &:extend(.transition all);
}
.form-icon {
  position: relative;
  .form-control {
    padding-left: 35 / @bs * 1rem;
  }
  .field_icon {
    position: absolute;
    top: 10 / @bs * 1rem;
    left: 10 / @bs * 1rem;
    z-index: 10;
    color: @white;
    .fs(18);
  }
}
/*---------------------------------------*\
   Buttons
\*---------------------------------------*/
.btn, a.btn {
  .fs(17);
  text-align: center;
  font-weight: 400;
  text-transform: uppercase;
  background-image: linear-gradient(to left, #306d91 0%, #50ade1 100%);
  border: 2px solid @primary;
  color: @white;
  padding: ( 20 / @bs * 1rem) (50 / @bs * 1rem);
  display: inline-block;
  letter-spacing: 1px;
  border-radius: 2px;
  text-decoration: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  vertical-align: middle;
  &:extend(.transition all);
  .hover({
    background: transparent;
    color: @white;
    background-image: linear-gradient(to right, #306d91 0%, #50ade1 100%);
  });
  &:active, &:focus {
    background: transparent;
    color: @white;
    background-image: linear-gradient(to right, #306d91 0%, #50ade1 100%);
  }
}